import Api from './api';
import { Plan } from '@/_types/plan.types';

// Affiliate filter: affiliate_id=xxx
export const getPlans = (affiliate?: object) => {
  return Api().get('/admin/plans', { params: affiliate });
};

export const getActivePlans = () => {
  return Api().get('/admin/plans/active');
};

export const createPlan = (plan: Plan) => {
  return Api().post('/admin/plans/', plan);
};

export const updatePlan = (plan: Plan) => {
  return Api().put(`/admin/plans/${plan._id}`, plan);
};

export const archivePlan = (plan: Plan) => {
  return Api().patch(`/admin/plans/archive/${plan._id}`, plan);
};

export const deletePlan = (planId: string) => {
  return Api().delete(`/admin/plans/${planId}`);
};

export const updateAffiliatePlansOrder = (planList: object) => {
  return Api().put('admin/affiliateusers/plans/order', planList);
};

export default {
  getPlans,
  getActivePlans,
  createPlan,
  updatePlan,
  archivePlan,
  deletePlan,
  updateAffiliatePlansOrder
};
