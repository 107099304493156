import { GetterTree } from 'vuex';
import { RootState } from '@/_store/types';
import { IndustriesState } from './types';

export const getters: GetterTree<IndustriesState, RootState> = {
  getIndustries(state) {
    return state.industries;
  },
  getIndustryName: state => (industryId: string) => {
    const searchResult = state.industries.find(industry => industry._id === industryId);
    return searchResult ? searchResult.name : '';
  },
  getIndustryType: state => (industryId: string) => {
    const searchResult = state.industries.find(industry => industry._id === industryId);
    return searchResult ? searchResult.type : '';
  },
  getIndustriesForPromotion: state => (type?: 'EC' | 'NON-EC' | null) => {
    // return only id and name
    return state.industries
      .filter(industry => {
        if (type) {
          return industry.type === type;
        }
        return true;
      })
      .map(industry => {
        return {
          value: industry._id,
          label: industry.name
        };
      });
  }
};
