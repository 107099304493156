export default {
  cancel: 'キャンセル',
  create: '制作',
  create_by_scraper: '自動制作',
  edit: '編集',
  delete: '削除',
  confirm: '確定',
  yes: 'はい',
  no: 'いいえ',
  copy: 'コピー',
  change: '变更',
  has_unsaved_changes: '編集をやめますか？',
  broadcast_prompt_message: 'ライブメッセージとして作成しますか',
  broadcast_warning:
    'ライブメッセージは全ユーザーに配信されます。また、一度配信すると編集できません。以上を了承された場合は、「<strong>確認</strong>」と入力してください',
  broadcast_confirmation_key: '確認',
  archive_prompt_confirm: '確定',
  archive_prompt_message: 'この{entity}をアーカイブしますか？',
  unarchive_prompt_message: 'この{entity}を有効にしますか？',
  last_item_warning_while_archiving: 'このプランはアフィリ最後のプランなので、アーカイブできません。',
  display_plans_at_max: '同じアフィリのプランは３つ表示されていますので、こちらのプランを表示されられません。',
  delete_prompt_confirm: '削除',
  delete_prompt_message: 'この{entity}を削除しますか？',
  ng_word_delete_prompt_message: '「{entity}」を削除しますか？',
  total_user_number: 'ユーザー',
  active_user_number: 'アクティブユーザー',
  copy_success: 'コピーしました！',
  copy_failure: 'コピーに失敗しました！',
  generic_error: '何か問題が発生しました！',
  update_success: '成功しました！',
  update_fail: '失敗しました！',
  pls_wait:
    'こちらの作業が完了するまでに数分かかる可能性がありますので、しばらくお待ちください。数分後、ページを再読み込みしてしてください。',
  reactive_prompt_message: '{entity}をアクティブに変更しますか？',
  deactive_prompt_message: '{entity}を停止しますか？',
  send_reminder_prompt_message: 'この{entity}を再送信します？',
  send_reminder_prompt_confirm: '再送信',
  yahoo_conf_modal_title: 'YahooアカウントIDの変更',
  yahoo_conf_modal_content:
    'このユーザーが広告を所持している場合は、その広告に影響を及ぼす恐れがあります。 本当によろしいですか？',
  make_shop_get_api_success: 'APIコードの取得に成功しました!',
  make_shop_get_api_failed: '取得が失敗しました。',
  shop_tag_install_success: 'インストールが完成しました！',
  shop_tag_install_failed: 'インストールが失敗しました。',
  updated_success: '編集しました！',
  updated_failed: '編集が失敗しました。。。',
  show_password: 'パスワードを表示する',
  email: 'メールアドレス',
  password: 'パスワード',
  update_daily_budget: '１日予算の変更は全てのユーザーに影響がありますので、変更でよろしいですか？',
  confirmation_charge_by_updated: '手数料設定を確認してください！値は件数か円かをよく見てくださいね。',
  searching: '🧐 頑張って探しています!',
  refresh_button: '更新ボタン',
  select_image: '',
  publish: 'リリース',

  shorthand: {
    sunday: '日',
    monday: '月',
    tuesday: '火',
    wednesday: '水',
    thursday: '木',
    friday: '金',
    saturday: '土'
  },

  calendar: {
    all: '全期間',
    custom: '任意',
    today: '今日',
    yesterday: '昨日',
    last_month: '先月',
    this_month: '今月',
    last_week: '先週',
    this_week: '今週',
    last_7_days: '過去7日間',
    last_14_days: '過去14日間',
    last_30_days: '過去30日間'
  },

  // Tip tap
  tip_tap: {
    tool: {
      link: 'リンク設定'
    },
    placeholder: {
      link: 'リンクを入力してください(https://...)'
    },
    action: {
      submit: '確定',
      cancel: 'キャンセル'
    }
  }
};
