import { getters } from '@/_store/modules/plans/getters';
import { actions } from '@/_store/modules/plans/actions';
import { mutations } from '@/_store/modules/plans/mutations';
import { PlanState } from './types';

export const state: PlanState = {
  plans: []
};

const namespaced = true;

export const plans = {
  state,
  namespaced,
  getters,
  actions,
  mutations
};
