import validation from './validation.json';
import admins from './admins.json';
import affiliateUser from './affiliateUser.json';
import auth from './auth.json';
import business_users from './business_users.json';
import campaign from './campaign';
import captcha from './captcha.json';
import daily_budget from './daily_budget.json';
import dashboard from './dashboard.json';
import dates from './dates.json';
import domainTable from './domainTable.json';
import facebook_accounts from './facebook_accounts.json';
import facebook from './facebook.json';
import flag_management from './flag_management.json';
import form from './form';
import futureshop from './futureshop.json';
import google_accounts from './google_accounts.json';
import industry from './industry.json';
import insight from './insight.json';
import line_accounts from './line_accounts.json';
import line_delivery_status_reasons from './line_delivery_status_reasons.json';
import log from './log.json';
import menu from './menu.json';
import modal from './modal';
import ng_words from './ng_words.json';
import notice from './notice';
import plan from './plan.json';
import platform from './platform.json';
import redirects from './redirects.json';
import shop from './shop.json';
import table from './table.json';
import themes from './themes.json';
import transaction from './transaction.json';
import user_command from './user_command.json';
import user_log from './user_log.json';
import user from './user.json';
import yahoo_accounts from './yahoo_accounts.json';
import yahoo from './yahoo.json';
import providers from './providers.json';
import language from './language';
import exports from './exports.json';
import open_ai from './openAi';
import promotion from './promotion';

export const translations = {
  jp: {
    validation,
    admins,
    affiliateUser,
    auth,
    business_users,
    campaign,
    captcha,
    dashboard,
    daily_budget,
    dates,
    domainTable,
    facebook_accounts,
    facebook,
    flag_management,
    form,
    futureshop,
    google_accounts,
    industry,
    insight,
    line_accounts,
    line_delivery_status_reasons,
    log,
    menu,
    modal,
    ng_words,
    notice,
    plan,
    platform,
    redirects,
    shop,
    table,
    themes,
    transaction,
    user_command,
    user_log,
    user,
    yahoo_accounts,
    yahoo,
    providers,
    language,
    exports,
    open_ai,
    promotion
  }
};
