import { ActionTree } from 'vuex';
import { RootState } from '@/_store/types';
import { IndustriesState } from './types';
import ApiIndustry from '@/services/industries.api';

export const actions: ActionTree<IndustriesState, RootState> = {
  async getIndustries({ commit }) {
    await ApiIndustry.getIndustries()
      .then((response: any) => {
        commit('setIndustries', response.data);
        return { data: response.data };
      })
      .catch((err: any) => {
        return { error: err };
      });
  }
};
