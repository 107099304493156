export default {
  page_title: '販促情報',
  page_title_create: '販促情報作成',
  null: '',
  default_title: 'タイトル',

  // Subtitle
  detail: '詳細',
  desktop: 'PC',
  mobile: 'モバイル',

  status: 'ステータス',
  image_url: '画像',
  title: 'タイトル',
  content: 'コンテンツ',
  target: '表示対象',
  initial_status: '初期ステータス',

  // Target Options
  created_at_range: '作成日',
  last_activity: '最終活動日',
  providers: '媒体',
  platforms: 'プラットフォーム',
  plans: '今月のプラン',
  industries: '業種',
  has_paused_campaign: '過去配信',
  has_active_campaign: '配信中',
  spent: '配信額',
  remaining_budget: '残り予算',
  follow_ai_recommendation: 'AIレコメンド',
  roas: 'ROAS(任意)',

  autocomplete_placeholder: 'デフォルトは全てを選択しています',
  empty_list: 'ECとNON-ECが同時に選択できる選択肢がありません',

  // list options
  Facebook: 'Facebook',
  Google: 'Google',
  Yahoo: 'Yahoo',
  LINE: 'LINE',

  カラーミーショップ: 'カラーミーショップ',
  MakeShop: 'MakeShop',
  BASE: 'BASE',
  Shopify: 'Shopify',
  WordPress: 'WordPress',
  WordPress_welcart: 'Welcart',
  WordPress_woocommerce: 'WooCommerce',
  futureshop: 'Futureshop',
  other_non_ec: 'その他(非物販)',
  other_ec: 'その他(物販)',

  yes: 'あり',
  no: 'なし',

  true: 'あり',
  false: 'なし',

  follow: 'フォロー',
  unfollow: 'フォローなし',

  above: '以上',
  below: '以下',
  start: 'から',
  end: 'まで',
  not_set: '設定なし',

  boundaries_error: '上限と下限の値が不正です。',

  // Reach
  reach: 'リーチ',

  // Mock will be replaced by real data
  industry_1: 'ファーション',
  industry_2: '金融',
  industry_3: 'CD・DVD・本',
  free_plan: 'フリー',
  standard_plan: 'スタンダード',
  premium_plan: 'プレミアム',

  // button
  create: '作成',
  next: '次へ',
  add_image: '画像追加',
  replace_image: '画像置換',
  edit_image: '画像編集',
  remove_all: '全フィルターを削除する',
  update: '更新',

  // Warning
  content_warning_message: '画像かテキストのどちらかを一つしか指定できません',
  content_warning_title: '優しい警告です',
  content_warning_confirm: 'うるさい',
  content_warning_cancel: 'やめとく',
  delete_warning_title: '危ない！',
  delete_warning_message: '{title}を削除でよろしいいですか',
  delete_warning_confirm: 'お願いします',
  delete_warning_cancel: 'また伺います',
  no_title: 'かわいそう無名の販促情報',

  create_failed: '作成に失敗しました',
  create_success: '作成に成功しました',
  update_failed: '更新に失敗しました',
  update_success: '更新に成功しました',
  delete_failed: '削除に失敗しました',
  delete_success: '削除に成功しました',

  // reach
  no_data: 'データがありません'
};
