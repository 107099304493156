import { GetterTree } from 'vuex';
import { RootState } from '@/_store/types';
import { PromotionState } from '@/_store/modules/promotion/types';

export const getters: GetterTree<PromotionState, RootState> = {
  getTitleSetting(state): boolean {
    return state.hasTitle;
  },
  getImageClickUrl(state): string {
    return state.image_click_url ?? '';
  },
  getPlatforms(state): { label: string; value: string }[] {
    return state.target.platforms;
  },
  getIndustries(state): { label: string; value: string }[] {
    return state.target.industries;
  },
  isDetailFilled(state): boolean {
    return (
      (!!(state.hasTitle && state.title.length > 0) || !state.hasTitle) &&
      (!!(state.content && state.content.length && state.content.length > 0) ||
        !!(
          state.image_url &&
          state.image_url.desktop &&
          state.image_url.desktop.length &&
          state.image_url.desktop.length > 0 &&
          state.image_url.mobile &&
          state.image_url.mobile.length &&
          state.image_url.mobile.length > 0
        ))
    );
  }
};
