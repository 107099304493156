import { getters } from '@/_store/modules/promotion/getters';
import { actions } from '@/_store/modules/promotion/actions';
import { mutations } from '@/_store/modules/promotion/mutations';
import { PromotionState } from './types';

export const state: PromotionState = {
  status: 'active',
  title: '',
  hasTitle: true,
  content: '',
  image_url: {
    desktop: '',
    mobile: ''
  },
  image_click_url: '',
  target: {
    created_at_range: {
      start: null,
      end: null
    },
    last_activity: {
      start: null,
      end: null
    },
    providers: [],
    platforms: [],
    plans: [],
    plan_labels: [],
    industries: [],
    industry_labels: [],
    has_paused_campaign: [],
    has_active_campaign: [],
    spent: {
      min: null,
      max: null
    },
    remaining_budget: {
      min: null,
      max: null
    },
    follow_ai_recommendation: [],
    roas: {
      min: null,
      max: null
    }
  }
};

const namespaced = true;

export const promotion = {
  state,
  namespaced,
  getters,
  actions,
  mutations
};
