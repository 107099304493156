import { GetterTree } from 'vuex';
import { RootState } from '@/_store/types';
import { PlanState } from './types';
import { IOption } from '@/modules/promotion/PromotionSettings.vue';

export const getters: GetterTree<PlanState, RootState> = {
  getPlans(state) {
    return state.plans;
  },
  getPlansForPromotion(state): IOption[] {
    // return only id and name
    return state.plans.map(plan => {
      return {
        value: plan._id,
        label: plan.name
      };
    });
  }
};
