export default {
  title: 'OpenAIの用量管理',
  request_token: '合計リクエスト数',
  total_usage: '合計使用した金額(USD)',
  total_usage_yen: '合計使用した金額(JPY)',

  // CSV
  default_filename: 'ファイル名',
  user_id: 'ユーザーID',
  shop_name: 'ショップ名',

  // Toast
  finished_download: 'ダウンロードが完了しました',
  failed_download: 'ダウンロードが失敗しました',

  // Details
  details: '使用詳細',
  provider: '媒体',
  product_name: '商品名',
  feature: '特長',
  ad_type: '広告タイプ',
  field: '分野',
  recommendations: '推薦',
  chosen: '選択',
  edited: '編集',
  auto: '自動',

  // Group by
  group_by: '集計単位',
  day: '日',
  week: '週',
  month: '月',
  year: '年',

  // adtype
  image_ad: '画像広告',
  catalog_ad: 'カタログ広告',
  search_ad: '検索広告',
  pmax_ad: 'PMAX広告',
  none: 'Googleお任せ',
  google_gdr: 'Google画像広告',
  google_dsa: 'Google検索広告',
  google_pmax: 'Google PMAX広告',

  // field
  headline: '広告見出し',
  headline_2: '広告見出し2',
  headline_3: '広告見出し3',
  long_headline: '長い広告見出し',
  description: '説明文',
  gdr_description: '説明文',
  description_2: '説明文2',
  catchphrase: 'キャチコピー',
  option: '表示オプション',
  option_2: '表示オプション2',
  support_text: 'リンクオプション',
  support_text_2: 'リンクオプション2'
};
