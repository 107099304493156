import Api from './api';
import { Industry } from '@/_types/industries.types';

const getIndustries = () => {
  return Api().get('/admin/industries');
};

const postIndustry = (industry: Industry) => {
  return Api().post('/admin/industries/', industry);
};

const putIndustries = (industry: Industry) => {
  return Api().put('/admin/industries/' + industry._id, industry);
};

const patchIndustries = (industry: any) => {
  return Api().patch('/admin/industries/' + industry._id, industry);
};

export default {
  getIndustries,
  postIndustry,
  putIndustries,
  patchIndustries
};
