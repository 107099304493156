import { ActionTree } from 'vuex';
import { RootState } from '@/_store/types';
import { PlanState } from './types';
import Api from '@/services/plan.api';

export const actions: ActionTree<PlanState, RootState> = {
  async getPlans({ commit }) {
    await Api.getPlans()
      .then((response: any) => {
        commit('setPlans', response.data);
        return { data: response.data };
      })
      .catch((err: any) => {
        return { error: err };
      });
  }
};
