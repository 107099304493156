import { IPromotion } from '@/_types/promotion.types';
import Api from './api';

export const getList = (filter?: any) => {
  return Api().get('/admin/promotions', { params: { ...filter } });
};

export const updatePromotion = (id: string, payload: IPromotion) => {
  return Api().put('/admin/promotions/' + id, payload);
};
export const createPromotion = (payload: IPromotion) => {
  return Api().post('/admin/promotions', payload);
};
export const deletePromotion = (id: string) => {
  return Api().delete('/admin/promotions/' + id);
};
export const getReach = (id: string, start: string, end: string) => {
  return Api().get(`/admin/promotions/reach/${id}?start=${start}&end=${end}`);
};

export default {
  getList,
  updatePromotion,
  createPromotion,
  deletePromotion,
  getReach
};
