import { MutationTree } from 'vuex';

export const mutations: MutationTree<any> = {
  setPromotion(state, promotion) {
    state.status = promotion.status;
    state.title = promotion.title;
    state.hasTitle = promotion.hasTitle;
    state.image_click_url = promotion.image_click_url;
    state.content = promotion.content;
    state.image_url = promotion.image_url;
    state.target = promotion.target;
  },

  setData(state, { label, value }) {
    state[label] = value;
  },

  setTargetOption(state, { label, value }) {
    state.target[label] = value;
  },

  toggleTitleSetting(state) {
    state.hasTitle = !state.hasTitle;
  },

  resetTargetOptions(state) {
    state.target = {
      created_at_range: {
        start: null,
        end: null
      },
      last_activity: {
        start: null,
        end: null
      },
      providers: [],
      platforms: [],
      plans: [],
      plan_labels: [],
      industries: [],
      industry_labels: [],
      has_paused_campaign: [],
      has_active_campaign: [],
      spent: {
        min: null,
        max: null
      },
      remaining_budget: {
        min: null,
        max: null
      },
      follow_ai_recommendation: [],
      roas: {
        min: null,
        max: null
      }
    };
  },

  resetState(state) {
    state.status = '';
    state.title = '';
    state.hasTitle = true;
    state.image_click_url = '';
    state.content = '';
    state.image_url = {
      desktop: '',
      mobile: ''
    };
    state.target = {
      created_at_range: {
        start: null,
        end: null
      },
      last_activity: {
        start: null,
        end: null
      },
      providers: [],
      platforms: [],
      plans: [],
      plan_labels: [],
      industries: [],
      industry_labels: [],
      has_paused_campaign: [],
      has_active_campaign: [],
      spent: {
        min: null,
        max: null
      },
      remaining_budget: {
        min: null,
        max: null
      },
      follow_ai_recommendation: [],
      roas: {
        min: null,
        max: null
      }
    };
  }
};
