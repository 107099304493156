import { getters } from '@/_store/modules/industries/getters';
import { actions } from '@/_store/modules/industries/actions';
import { mutations } from '@/_store/modules/industries/mutations';
import { IndustriesState } from './types';

export const state: IndustriesState = {
  industries: []
};

const namespaced = true;

export const industries = {
  state,
  namespaced,
  getters,
  actions,
  mutations
};
