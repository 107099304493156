export default {
  page_title: '言語翻訳',
  image_number: '画像数: ',
  add_image: '画像を追加',
  editLogo: 'ロゴの編集',
  rotate_left: '左回転',
  rotate_right: '右回転',
  shrink: '縮小',
  expand: '拡大',
  select_image: '画像を選ぶ',
  image_message: '以下の30枚の画像はWordPressより取得した最新の画像です。',
  square_shape: '正方形',
  rectangle_shape: '長方形',
  rectangle_1: '長方形１',
  rectangle_2: '長方形２',
  tag: 'タグ',
  trimming: 'トリミング',
  select_page: 'ページを選択',
  trimming_tip: '正方形、長方形１、長方形２の３サイズのロゴを各サイズ編集できます。',
  trimming_tip_2: '正方形と長方形を各サイズ編集できます。',
  both_shape: '正方形と長方形',
  export: 'ダウンロード',
  upload: 'アップロード',
  isExistOrder: 'この注文: {order} は既に行われています',
  add_language: '新しい言語を追加',
  key_column: '鍵',
  platform_column: 'プラットホーム',
  type_column: '種類',
  category_column: '特徴',
  memo_column: 'メモ',
  history_title: '翻訳された歴史',
  publish_warning: 'すべてのデータを公開してもよろしいですか?',
  status_column: 'ステータス',
  language_column: '言語',
  code_column: 'コード',
  order_column: '注文',
  keyword_placeholder: '任意のキーワード...',
  search_missing_keys: '<b>{number}</b> キーが翻訳されていません。',
  show_missing: '表示',
  hide_missing: '完了',
  history: {
    add: {
      imported: 'ファイルアップロードで{admin}が{filePath}から<b>{to}</b>を追加されました。',
      migrated: 'Migrationで{admin}が<b>{to}</b>を追加されました。'
    },
    update: {
      imported: '{admin}がファイルをアップロードされたとき、{filePath}で<b>{from}</b>を<b>{to}</b>に変更されました。',
      migrated: 'Migrationで{admin}が<b>{from}</b>を<b>{to}</b>に変更されました。',
      changed: 'UIで{admin}が<b>{from}</b>を<b>{to}</b>に変更されました。'
    }
  }
};
