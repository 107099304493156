export default {
  csv_email_success: 'こちらの作業が完了するまでに数分かかる可能性がありますので、しばらくお待ちください。',
  csv_email_error: 'システムエラーが発生しました。もう一度お試しください。',
  error_change_budget: 'キャンペーン予算変更は失敗しました。',
  warning_change_budget: '必ずビジネスマネージャーも予算を更新してください。',
  ng_words_campaign_name: '景品単語：{name}',
  ng_words_close: '閉じる',
  ng_words_exists: '「 {name} 」 広告は違反単語を使用されています。',
  ng_words_list: '違反単語：',
  display: 'ティスプレイ広告',
  search: '検索広告',
  google_ssc: 'スマートショッピング広告',
  google_pla: 'ショッピング広告',
  google_pmax: 'パフォーマンス広告',
  campaign_type: 'ネットワーク',
  ai_campaign: 'AI実装されたCP',
  ai_campaign_installed: '実装された',
  ai_campaign_not_installed: '実装されていない',
  send_email: 'メールで送る',
  coupon: 'クーポン',
  charge_type: '予算追加方法',
  charge_auto_monthly: '自動（月）',
  charge_auto_remaining: '自動（残）',
  charge_manual: '設定なし',

  // AI campaigns
  export_ai: 'AIデータ',
  ai_campaign_export_title: 'AIキャンペーン出力設定',
  user_id: 'ユーザーID',
  total_budget: '合計予算',
  average_product_price: '平均商品価格',
  initial_budget: '最初に記入した予算',
  final_budget: '決めた予算',
  shop_name: 'ショップ名',
  shop_url: 'ショップURL',
  final_bigger: '予算変更あり',
  industry: 'カテゴリ',
  adsist_plan: '構築パック',
  total_campaign: '合計CP',
  valid_campaign: '残りCP',
  status_active: '進行中',
  days_since_created: 'Date',
  roas: 'ROAS',
  campaign_name: 'キャンペーン名',
  campaign_strategy: '戦略',
  campaign_daily_budget: '1日の予算',
  campaign_status: 'ステータス',
  campaign_spend: '利用金額合計',
  campaign_impressions: '表示回数',
  campaign_reach: 'リーチ',
  campaign_clicks: 'クリック数',
  campaign_cpc: 'クリック単価',
  campaign_cpm: 'CPM',
  campaign_conversions: '獲得数',
  campaign_conversions_value: '広告経由の売上',
  campaign_conversions_value_per_clicks: '獲得率',
  campaign_cost_per_conversion: '獲得数単価',
  campaign_id: 'キャンペーンID',
  email: 'メール',
  download: 'ダウンロード',
  destination: '出力方法',
  period: '出力期間',
  select_period: '期間を選択',
  export_options: '出力オプション',
  request_sent: 'データは準備中です、しばらくお待ちください',
  confirm: 'これで良い！',
  select_all: '全部を選択する',
  remove_all: '選択を消す',
  email_tooltip: 'デフォルトのメールアドは「admin@adsist.ai」です',
  different_email: '他のメールアドに送る',
  different_email_label: 'メールアド',
  different_email_placeholder: 'test@test.com',
  spread_sheet_url: 'ファイルURL',
  custom_sheet_name: '任意のシート名',
  default_sheet_name: 'デフォルト: {date}',
  platform_id: 'プラットフォーム',
  suggested_budget: 'レコメンド予算',
  predicted_roas: '予測ROAS',
  provider: '媒体',

  // AI prompts
  image_ad: '画像広告',
  catalog_ad: 'カタログ広告',
  search_ad: '検索広告',
  pmax_ad: 'PMAX広告',

  ai_prompts: {
    id: 'ID',
    ad_type: '広告タイプ',
    content_type: '項目タイプ',
    provider: '媒体',
    request_message: 'メッセージ',
    system_prompt: 'システム用語',
    back: 'やめとく',
    confirm: 'これで良い！',
    number_wrapper: '{text}{number}',

    option: '表示オプション',
    support_text: 'リンクオプション',
    headline: '見出し',
    catchphrase: 'キャッチコピー',
    description: '説明文',
    long_headline: '長い見出し',

    edit_title: 'AI用語編集',
    edit_helper: '「productName」と「feature」はユーザーが入力された言葉で交換します。',
    tryout: '試し',

    product_name: '商品名',
    feature: '特徴',
    generate: '生成',
    select_one: '{text}を選択',

    reset_system_prompt:
      'あなたは広告文のアドバイザーで、ユーザーのリクエストを聞きながら、広告文を書いてください。ただし、以下の言葉を使えないようにしてください、'
  }
};
