export default {
  confirm: '確認',
  cancel: 'やめる',
  prev: '戻る',
  set: '決定',
  reset: 'リセット',
  save: '保存',

  // Image editor
  single_image_cropper: '画像編集',
  ratio: '比率'
};
