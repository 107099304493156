import Vue from 'vue';
import router from './router';
import App from './modules/App.vue';
import store from '@/_store';
import Buefy from 'buefy';
import '@/assets/sass/style.scss';

import i18n from './i18n';
import i18nMixin from '@/i18nMixin';
import '@/_helpers/directives';
import Vuelidate from 'vuelidate';
import Croppa from 'vue-croppa';

// Sentry
import * as Sentry from '@sentry/vue';
import { ReportingObserver as ReportingObserverIntegration } from '@sentry/integrations';

import { DefaultLayout, DashboardLayout } from '@/shared/components/layout';
import './vee-validate.ts';
import 'vue-croppa/dist/vue-croppa.css';

Vue.component('default-layout', DefaultLayout);
Vue.component('dashboard-layout', DashboardLayout);

Vue.mixin(i18nMixin);
Vue.use(Buefy);
Vue.use(Vuelidate);
Vue.use(Croppa);

Vue.config.productionTip = false;

Sentry.init({
  Vue: Vue,
  dsn: 'https://abc0fd3fbfcc4554bfa8f79ab2fb0131@o361280.ingest.sentry.io/4104581',
  release: 'AdSIST Admin@' + process.env.VUE_APP_VERSION,
  integrations: [new ReportingObserverIntegration()],
  tracingOptions: {
    trackComponents: true
  },
  debug: true,
  environment: process.env.VUE_APP_ENV
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
