import { ActionTree } from 'vuex';
import { RootState } from '@/_store/types';
import { PromotionState } from './types';
import Api from '@/services/promotion.api';
import { IPromotion } from '@/_types/promotion.types';

export const actions: ActionTree<PromotionState, RootState> = {
  async createPromotion({ state, commit }) {
    // API here with obj
    let data = getData(state);
    await Api.createPromotion(data).catch(error => {
      return { error };
    });
    commit('resetState');
    return { data };
  },

  async updateStatus(state, record: IPromotion) {
    await Api.updatePromotion(record._id, record).catch(error => {
      return { error };
    });

    return { data: record };
  },

  async updatePromotion({ state, commit }, id: string) {
    // API here with obj
    let data = getData(state);
    await Api.updatePromotion(id, data).catch(error => {
      return { error };
    });
    commit('resetState');
    return { data };
  }
};

const getData = (state: PromotionState): IPromotion => {
  let obj: { [key: string]: any } = {
    title: state.hasTitle ? state.title : null,
    content: state.content,
    image_url: state.image_url,
    image_click_url: state.image_click_url ?? '',
    target: state.target,
    status: state.status
  };

  // if content is null, image_url is required
  if (obj.content) {
    delete obj.image_url;
  } else {
    if (!obj.image_url || !obj.image_url.desktop || !obj.image_url.mobile) {
      throw new Error('image_url is required when content is null');
    }
  }

  ['has_paused_campaign', 'has_active_campaign', 'follow_ai_recommendation'].forEach(field => {
    if (obj.target[field] !== null) {
      let value = obj.target[field];
      if (value && value.length && value.length === 1) {
        obj.target[field] = JSON.parse(value[0]);
      } else {
        obj.target[field] = null;
      }
    }
  });

  ['plans', 'industries', 'platforms'].forEach(field => {
    if (!(obj.target[field] && obj.target[field].length)) {
      return;
    }

    switch (field) {
      case 'plans':
        obj.target.plan_labels = obj.target[field].map((item: any) => item.label);
        break;
      case 'industries':
        obj.target.industry_labels = obj.target[field].map((item: any) => item.label);
        break;
    }
    obj.target[field] = obj.target[field].map((item: any) => item.value);
  });

  ['remaining_budget', 'spent', 'roas'].forEach(field => {
    if (obj.target[field] !== null) {
      obj.target[field] = {
        max: obj.target[field].max !== null ? Number(obj.target[field].max) : null,
        min: obj.target[field].min !== null ? Number(obj.target[field].min) : null
      };
    }
  });

  return obj as IPromotion;
};
